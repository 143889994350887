import * as React from 'react';

const PencilIcon = ({
  style = { width: '20px', margin: '0 24px' },
  width = '20px',
  height = '20px',
  stroke = '#FFF',
  className = '',
  viewBox = '0 0 20 20',
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    className={`svg-icon ${className || ''}`}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke={style.stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M16.25 8.75l-5-5M6.875 18.125l-6.25 1.25 1.25-6.25 12.5-12.5 5 5z" />
    </g>
  </svg>
);

export default PencilIcon;
